import {toArray} from '@ivosabev/helpers/toArray';
import {Image as UImage} from '@unpic/react';

const avif: Record<string, string[]> = import.meta.glob('/app/**/*.{jpg,jpeg,png}', {
  eager: true,
  import: 'default',
  query: {format: 'avif', picture: '', w: '600;1200;1600', withoutEnlargement: true},
});
const webp: Record<string, string[]> = import.meta.glob('/app/**/*.{jpg,jpeg,png}', {
  eager: true,
  import: 'default',
  query: {format: 'webp', picture: '', w: '600;1200;1600', withoutEnlargement: true},
});
const fallback: Record<string, string> = import.meta.glob('/app/**/*.{jpg,jpeg,png}', {
  eager: true,
  import: 'default',
  query: {quality: 90},
});
const metadata: Record<string, Record<string, any>> = import.meta.glob('/app/**/*.{jpg,jpeg,png}', {
  eager: true,
  import: 'default',
  query: {as: 'metadata'},
});
const images = {avif, fallback, metadata, webp};

export function Image({src, ...rest}: any & React.RefAttributes<HTMLImageElement>) {
  if (!src) {
    return null;
  }

  if (src.indexOf('https://') === 0) {
    return <UImage src={src} {...rest} />;
  }

  const key = `/app/assets/images${src}`;

  const avif = images.avif[key];
  const webp = images.webp[key];
  const fallback = images.fallback[key];
  const metadata = images.metadata[key];

  if (!metadata) {
    return null;
  }

  const map = {} as Record<string, [string, string]>;
  const avifImages = toArray(avif);
  const webpImages = toArray(webp);

  // 600 sm, 1200 md, 1600 lg
  const keys = ['sm', 'md', 'lg'];
  for (let i = 0; i < keys.length; i++) {
    map[keys[i]] = [avifImages[i] || avifImages[avifImages.length - 1], webpImages[i] || webpImages[webpImages.length - 1]];
  }

  /* eslint-disable sort-keys-fix/sort-keys-fix */
  const tmp1 = {
    '(min-width: 1600px)': `${map['lg'][0]} 1x`,
    '(min-width: 800px)': `${map['md'][0]} 1x`,
    '(min-width: 400px)': `${map['sm'][0]} 1x`,
  } as Record<string, string>;
  const tmp2 = {
    '(min-width: 1600px)': `${map['lg'][1]} 1x`,
    '(min-width: 800px)': `${map['md'][1]} 1x`,
    '(min-width: 400px)': `${map['sm'][1]} 1x`,
  } as Record<string, string>;
  /* eslint-enable sort-keys-fix/sort-keys-fix */

  return (
    <picture>
      {Object.keys(tmp1).map((k) => {
        const src = tmp1[k];
        return (
          <source
            key={`avif-${k}`}
            media={k}
            srcSet={src}
            type="image/avif"
          />
        );
      })}
      {Object.keys(tmp2).map((k) => {
        const src = tmp2[k];
        return (
          <source
            key={`webp-${k}`}
            media={k}
            srcSet={src}
            type="image/webp"
          />
        );
      })}
      <UImage
        height={metadata.height}
        src={fallback}
        width={metadata.width}
        {...rest}
      />
    </picture>
  );
}
